<template>
  <div class="modal-card is-relative">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("business_matching.business_matching_cancel_meeting_title") }}
      </h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <b-loading :active="isRequesting" :is-full-page="false"></b-loading>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
        <button class="button is-fullwidth is-danger" style="margin-top: 1rem" @click="onConfirm">
          {{ $t("common.yes_lowercase") }}
        </button>
        <button class="button is-fullwidth" style="margin-top: 1rem" @click="$parent.close()">
          {{ $t("common.no_lowercase") }}
        </button>
      </div>
      <div v-if="error" class="mt-1 has-text-danger has-text-centered">
        {{ error | pluckError }}
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import { mapActions, mapGetters } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";

export default {
  name: "BMCancelMeetingModal",

  props: ["meeting"],

  mixins: [RequestMixin],

  components: { CloseIcon },

  methods: {
    ...mapActions("businessMatchingMeetings", ["deleteMeeting", "declineMeeting"]),

    onConfirm() {
      this.delete();
    },
  },

  computed: {
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),

    isFromCurrentUser() {
      return this.meeting.from_user_id === this.currentUserUuid;
    },
  },

  requests: {
    async delete() {
      if (this.isFromCurrentUser) {
        await this.deleteMeeting({
          componentId: this.meeting.event_component_id,
          meetingId: this.meeting.id,
        }).then(result => {
          this.$parent.close();
        });
      } else {
        await this.declineMeeting({
          componentId: this.meeting.event_component_id,
          meetingId: this.meeting.id,
        }).then(result => {
          this.$parent.close();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
  }
}
</style>
